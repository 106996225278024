.data-coverage-container {
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%;
}

.counter-container {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: auto;
    z-index: 10;

    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    margin-bottom: 24px;
    margin-left: -16px;
    margin-right: 0;
} 

/* Counters */
/* .counter-container > *:last-child {
    margin-right: 16px;
} */

.data-coverage-map {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* padding-top: 50%; /* 2:1 Aspect Ratio */
    /*padding-top: 56.25%;*/ /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}
