/* Navigation header en footer */
.footer {
    color: var(--light-blue);
    background-color: var(--dark-blue-a);
}

.footer-copyright {
    background-color: var(--dark-blue);
}

.footer a {
    color: #55acee;
}