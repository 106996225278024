
/* Header carousel */
.carousel-slides,
.carousel-slides .active,
.carousel-item {
    height: calc(100vh - var(--navbar-height));
}

.header-carousel {
  min-height: calc(100vh - var(--navbar-height));
}
