.card-img-top--fill {
    height: 200px;
}

.card-body {
    position: relative;
    padding-bottom: 32px;
}

.card-footer {
    width: 100%;
    position: absolute;
    bottom: 12px;
    text-align: center;
    margin-left: -16px;
    margin-right: -16px;
}