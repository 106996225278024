/* CSS variables */
:root {
  --light-blue: rgb(243, 248, 253);
  --dark-blue: rgb(28, 35, 49);
  --dark-blue-a: rgba(28, 35, 49, 0.9);
  --dark-transparant: rgba(0,0,0,0.2);
  --navbar-height: 68px;
  --border-radius: 4px;
}

/* -------------------------------------------- */

html {
  scroll-behavior: smooth;
}

.view,
body,
html {
    height: 100%;
}

/* Border-box for all DOM elements*/
* {
  box-sizing: border-box;
}

/* .nav-link.active {
  font-weight: 600;
} */

/* -------------------------------------------- */

hr {
  width: 100%;
}

/* -------------------------------------------- */

/* Sections styling */
.section-accent {
  background-color: var(--light-blue);
  /* background-image: linear-gradient(-225deg, var(--light-blue) 0%, #D7FFFE 100%); */
}

.section-accent-dark {
  color: var(--light-blue);
  background-color: var(--dark-blue-a);
}

.light-blue-text {
  color: var(--light-blue);
}

.section-fill {
  min-height: calc(100vh - var(--navbar-height)); /* Navbar height */
}

.section-fill > .container {
  min-height: calc(100vh - var(--navbar-height) - 24px - 24px); /* Navbar height and container padding */
}

/* -------------------------------------------- */

/* Round button style */
.btn-round {
  border-radius: 50%;
  line-height: 1;
  font-size: 12px;
  padding: 8px 11px;
}

/* -------------------------------------------- */

/* testimonials */

.testimonial-img {
  max-width: 100px;
  max-height: 100px;
}

.testimonial-title {
  margin-top: 12px;
}

.testimonial-subtitle {
  margin: 12px 0;
}

/* .testimonial-remark {
}

.testimonial-rating {
} */

/* -------------------------------------------- */

/* General */

.scroll-fix {
  position: relative;
}

.scroll-fix-item {
  position: absolute;
  top: calc(-1 * var(--navbar-height));
}

.no-pointer-events {
  pointer-events: none;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.border-4 {
  border-width: 4px !important;
}

/* .carousel-inner .carousel-item.carousel-item--trans-5 { */
  /* transition: transform 8s linear, -webkit-transform 8s linear; */
/* } */

/* .nav-link.active {
  border: 1px solid white;
} */

.image-section {
  min-height: calc(50vh - (var(--navbar-height) / 2));
}

.sectionbgTop, .sectionbgBottom {
  position: relative;
}

.sectionbgTop::before {
  content: '';
  position: absolute;
  width: inherit;
  height: 150px;
  background-size: auto 150px;
  left: 0;
  right: 0;

  top: -149px;
}

.sectionbgBottom::after {
  content: '';
  position: absolute;
  width: inherit;
  height: 150px;
  background-size: auto 150px;
  left: 0;
  right: 0;

  bottom: -149px;
  z-index: 10;
}

/*
.sectionbgUp::before {
  background: url(/img/paths/section_1_up_2.svg);
}

.sectionbgDown::after {
  background: url(/img/paths/section_1_down_1.svg);
}

.sectionbgDown2::after {
  background: url(/img/paths/section_1_down_2.svg);
}

.sectionbgDownAccent::after {
  background: url(/img/paths/section_2_down_2.svg);
}
*/

.section-title {
  color: var(--dark-blue);
}

.section-accent-dark .section-title {
  color: white;
}

.section-accent-dark hr {
  background-color: white;
}

/* .section-bg-image {
  background-image: url("/img/content/webviewer_detail.jpg");

  filter: blur(8px);
  -webkit-filter: blur(8px);

  height: 100%; 
  width: 100%; 

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
} */

.section-bg {
  position: relative;
}

.section-bg-image {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 0;

  display: block;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.section-bg-image.blur {
  -webkit-filter: blur(8px);
  -moz-filter: blur(8px);
  -o-filter: blur(8px);
  -ms-filter: blur(8px);
  filter: blur(8px);
}

.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}

.z-10 {
  z-index: 10;
}

.m-minus-4 {
  margin: -24px;
}

.fa-md {
  font-size: 24px;
}

/* Google map styling */

#map [tabindex="0"] div:first-child {
  will-change: transform;
}

/* .gmnoprint > .gm-style-mtc {
} */

.gm-style-mtc > div {
  padding: 0 8px !important;
  font-size: 14px !important;
  border-radius: 0 !important;
}

.h-70 {
  height: 70% !important;
}

.h-30 {
  height: 30% !important;
}

.min-h-100 {
  min-height: 100% !important;
}



/* Client carousel */
/* Slider */
.slick-slide {
    margin: 0px 20px;
}

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus {
    outline: none;
}
.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: -webkit-box; 
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex; 
    display: flex;
    align-items: center;
}
.slick-track:before,
.slick-track:after {
    display: table;

    content: '';
}
.slick-track:after {
    clear: both;
}
.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide {
    float: right;
}
.slick-slide img {
    display: block;
}
.slick-slide.slick-loading img {
    display: none;
}
.slick-slide.dragging img {
    pointer-events: none;
}
.slick-initialized .slick-slide {
    display: block;
}
.slick-loading .slick-slide {
    visibility: hidden;
}
.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}


/* Clickable card */
.card--clickable {
  cursor: pointer;
  transition: transform .25s;
}

.card--clickable:hover {
  transform: scale(1.05);
}

.card--clickable .overlay {
  cursor: pointer;
}

/* -------------------------------------------- */

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}


.carousel-control-prev-icon, .carousel-control-next-icon {
  height: 32px !important;
  width: 32px !important;
  background-color: var(--dark-transparant);
  border-radius: 50%;;
}

.carousel-control-large {
  height: 48px !important;
  width: 48px !important;
}


/* Overwrite default mdb behaviour */
/* .btn {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
} */

.navbar-dark .navbar-nav .nav-link {
  font-weight: 300;
  color: #FFF;
}

.w-header {
  width: 3.2rem;
  margin-left: auto;
  margin-right: auto;
}

.rgba-blue-light {
  background-color: rgba(59, 150, 219, 0.8);
}

.fs-small {
  font-size: 14px;
}

.fs-smaller {
  font-size: 10px;
}

.scale-85 {
  transform: scale(0.85);
}

.mt-n-btn {
  margin-top: -17.5px;
}

.mt-n-2 {
  margin-top: -2rem;
}

.text-note {
    font-size: 14px;
    color: #9FA6B2;
}
