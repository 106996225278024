.rgba-black-light--darker {
  /* Overrule rgba-black-light class. Make it a bit darker */
  background-color: rgba(0, 0, 0, 0.3);
}

.header-carousel-item {
    height: 100%;
}

.header__img {
  z-index: 20;
  height: 50px;
}

.header__img-hide-small {
  display: none !important;
}

@media (min-width: 576px) {
  .header__img {
    height: 120px;
  }

  .header__img-hide-small {
    display: block !important;
  }  
}