/* Overlay achtergrond om de modal te verduisteren */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  /* De pop-up card zelf */
  .popup-card {
    width: 400px;
    max-width: 90%;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  /* De inhoud van de pop-up */
  .popup-body {
    padding: 20px;
    color: #333;
  }
  
  /* Iconen voor succes of fout */
  .popup-icon {
    font-size: 3rem;
    color: #007bff; /* Standaard kleur voor succes */
    margin-bottom: 15px;
  }
  
  .popup-success .popup-icon {
    color: green;
  }
  
  .popup-error .popup-icon {
    color: red;
  }
  
  /* Titel van de pop-up */
  .popup-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 10px 0;
  }
  
  /* Berichttekst in de pop-up */
  .popup-message {
    font-size: 1rem;
    color: #666;
    margin-bottom: 20px;
  }
  
  /* Footer van de pop-up met de sluitknop */
  .popup-footer {
    background-color: #f9f9f9;
    padding: 15px;
    border-top: 1px solid #e0e0e0;
  }
  
  .popup-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .popup-button:hover {
    background-color: #0056b3;
  }
  